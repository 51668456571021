<template>
  <v-dialog v-model='dialog' persistent width='600px'>
    <template v-slot:activator='{ on, attrs }'>
      <v-btn text @click='openDialog()'>
        <v-icon color='gray' left small>mdi-file-document-outline</v-icon>
        {{ $t('general.titles.nomenclators.downloadTemplate').toUpperCase() }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class='headline'>
        {{ $t('general.titles.nomenclators.downloadTemplate') }} - {{ $t('modules.name.StorageDevice') }}
        <v-spacer />
        <v-btn text icon fab @click="dialogTemplateCSVClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols='12'>
            <v-alert
              border='left'
              class='mt-2'
              color='orange'
              dense
              icon='mdi-alert-outline'
              text
              type='warning'
            >
              {{ $t('general.titles.warningUploadTemplate') }}
            </v-alert>

            <v-divider></v-divider>

            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('general.titles.nomenclators.downloadTemplate') }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ $t('general.titles.uploadTemplateNote') }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn :disabled='loading' :loading='loading' color='secondary'
                         outlined small tile @click='fetchTemplate'>
                    {{ $t('general.buttons.generate') }}
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LanguageService from '@/services/LanguajeService'
import i18n from '@/plugins/i18n'

export default {
  name: 'DownloadCSVTemplateComponent',

  props: {},

  data: () => ({
    dialog: false,
    loading: false,
    language: LanguageService.getLenguajeName(),
  }),

  methods: {
    ...mapActions({
      fetchTemplateAction: 'storage/fetchTemplate',
    }),
    openDialog() {
      this.$emit('closeMenu', false)
      this.dialog = true
    },
    async fetchTemplate() {
      this.loading = true

      await this.fetchTemplateAction([{
        language: LanguageService.getLenguajeName()
      }, this.$toast]).finally(() => {
        this.loading = false
      })
    },
    dialogTemplateCSVClose() {
      this.dialog = false
      this.language = LanguageService.getLenguajeName()
      this.loading = false
    },
    translate(key, uppercase) {
      return LanguageService.getKey3(key)
    },
  },
}
</script>

<style scoped>

</style>