<template>
  <v-dialog v-model='dialog' persistent width='600px'>
    <template v-slot:activator='{ on, attrs }'>
      <v-btn text @click='openDialog()'>
        <v-icon color='gray' left small>mdi-upload-multiple</v-icon>
        CSV
      </v-btn>
    </template>
    <v-card>
      <v-card-title class='headline'>
        {{ $t('reports.titles.importConfigCSV') }}
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols='12'>
              <v-file-input
                outlined
                v-model='file'
                :label="$t('general.buttons.upload_doc')"
                :rules='requireRulesSizeAndType(file).concat(required)'
                append-icon='mdi-file-excel'
                color='secondary'
                prepend-icon=''
                @change='clearErrors()'
              >
              </v-file-input>
            </v-col>
            <v-col cols='12'>
              <v-card elevation='0' v-if='errors.length > 0'>
                <v-card-title class='red--text px-0 mx-0'>
                  <strong>{{ $t('records.titles.errorsList') }}</strong>
                </v-card-title>
                <v-card-text class='px-0 mx-0'>
                  <v-list dense style='max-height: 100% !important; height: 10vh; overflow-y: auto !important;'>
                    <v-list-item class='d-block mx-1 px-0' v-for='(error, index) in errors' :key='index'>
                      <v-list-item-title class='black--text subtitle-1'>
                        <strong>{{ $t('modules.name.StorageDevice') }} #{{ error.record }}</strong>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                    <span class='d-block' v-for="(message, key) in error.messages" :key='key'>
                      {{ message }}
                    </span>
                      </v-list-item-subtitle>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols='12' class='d-flex justify-end py-0 my-0'>
              <v-chip v-if='count.success' class='mr-2' small><strong>{{ $t('notifications.nomenclator.success') }}:</strong> {{ count.success }}</v-chip>
              <v-chip v-if='count.errors' class='mr-2' small><strong>{{ $t('notifications.nomenclator.errors') }}:</strong> {{ count.errors }}</v-chip>
              <v-chip v-if='count.total' class='mr-2' small><strong>{{ $t('notifications.nomenclator.total') }}:</strong> {{ count.total }}</v-chip>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled='loading'
          color='gray'
          small
          text
          @click='dialogImportCSVClose'
          v-if='errors.length > 0'
        >
          {{ $t('general.buttons.close') }}
        </v-btn>
        <span v-if='!(errors.length > 0)'>
          <v-btn
            :disabled='loading'
            color='gray'
            small
            text
            @click='dialogImportCSVClose'
          >
            {{ $t('general.buttons.cancel') }}
          </v-btn>
          <v-btn
            :disabled='loading || !valid'
            :loading='loading'
            color='secondary'
            small
            text
            @click='importRecords()'
          >
            {{ $t('general.buttons.confirm') }}
          </v-btn>
        </span>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LanguageService from '@/services/LanguajeService'

export default {
  name: 'ImportCSVComponent',

  props: {},

  data: () => ({
    dialog: false,
    loading: false,
    valid: false,
    language: LanguageService.getLenguajeName(),
    file: null,
    errors: [],
    count: {
      success: null,
      errors: null,
      total: null
    }
  }),

  computed: {
    ...mapGetters({
      requireRulesSizeAndType: 'general/requireRulesSizeAndType',
      required: 'general/requireRules',
    }),
  },

  methods: {
    ...mapActions({
      importStoragesAction: 'storage/importStorages',
    }),
    openDialog() {
      this.$emit('closeMenu', false)
      this.dialog = true
    },
    async importRecords() {
      this.loading = true

      await this.importStoragesAction([{
        language: LanguageService.getLenguajeName(),
        file: this.file,
      }, this.$toast])
        .then((res) => {
          if(res.importState === "SUCCESSFUL") {
            this.dialogImportCSVClose()
          } else {
            this.errors = res.recordsFailed
            this.count = {
              success: res.itemsImported,
              errors: res.recordsFailed.length,
              total: res.totalItems,
            }
            this.loading = false
          }

          this.$root.$emit('setFiltersLocations')
        })
        .catch((err) => {
          if(err.recordsFailed) {
            this.errors = err.recordsFailed
            this.loading = false
          } else {
            this.loading = false
          }
        })
    },
    dialogImportCSVClose() {
      this.dialog = false
      this.count = {
        success: null,
        errors: null,
        total: null
      }
      this.language = LanguageService.getLenguajeName()
      this.file = null
      this.loading = false
      this.errors = []
      this.$refs.form.reset()
    },
    translate(key, uppercase) {
      return LanguageService.getKey3(key)
    },
    clearErrors() {
      this.count = {
        success: null,
        errors: null,
        total: null
      }
      this.errors = [];
    }
  },
}
</script>

<style scoped>
.v-chip {
  border-radius: .2em !important;
}
</style>