import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"400px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{attrs:{"elevation":"0"}},[_c(VCardTitle,[_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close:dialog')}}},[_c(VIcon,[_vm._v(" mdi-close ")])],1)],1),_c(VSheet,{staticClass:"mb-4 pa-4 d-flex justify-center",attrs:{"color":"white"}},[_c('div',{attrs:{"id":"qrCode"}},[_c('vue-qrcode',{attrs:{"options":{ size: 240 },"value":_vm.url}})],1)]),_c(VCardText,{staticClass:"py-6 d-flex justify-space-around align-center"},[_c(VBtn,{attrs:{"color":"secondary darken-1","outlined":"","small":""},on:{"click":_vm.copyUrl}},[_vm._v(" "+_vm._s(_vm.copied ? 'Copiado' : 'Copiar URL')+" ")]),_c(VBtn,{attrs:{"color":"secondary darken-1","outlined":"","small":""},on:{"click":_vm.downloadQr}},[_vm._v(" Descargar QR ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }