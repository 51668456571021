import _ from "lodash";
import hexToRgba from "hex-to-rgba";
import {mapGetters} from "vuex";
import N_UnitsService from "@/services/N_UnitsService";
import MapService from "../services/MapService";
import { convertLatitude, convertLongitude, isValidLatLong } from "../../utils/convert-to-DMS-format";
import * as turf from "@turf/turf";

export const mapboxMixin = {
    computed: {
        ...mapGetters({
            allUnits: "units/allObjectUnits",
            storages: "authority/authoritiesStoragesDevices",
            locations: "authority/authoritiesLocations",
            storageDeviceTypesActiveByCompany: "storage_type/storageDeviceTypesActiveByCompany",
        }),
        mapConfig: {
            get() {
                return this.$store.getters['general/mapConfig']
            },
            set(val) {
                this.$store.commit('general/SET_MAP_CONFIG', val)
            },
        },
    },

    methods: {
        async reload(view) {
            this.units = this.allUnits;
            if (this.editedIndex !== null) {
                this.editedItem = Object.assign(
                    {},
                    this.item
                );
                this.editedItem.storage_device_type_id = this.editedItem
                    .storage_device_type_id
                    ? this.storageDeviceTypesActiveByCompany.find(
                        (x) =>
                            x.storage_device_type_id ===
                            this.editedItem.storage_device_type_id
                    )
                    : null;
            }
            this.turfDefaultUnit = _.find(this.units, {code: "ha"});
            this.editedItem.measurementUnit = this.editedItem.measurementUnit
                ? _.find(this.units, {id: this.editedItem.measurementUnit.id})
                : null;
            this.areaSuffix = "ha";

            // Delete map config
            this.$refs.mapLocations.deleteMapConfig();

            // Get Data and config paint layer locations
            const featureLocations = await this.getDataLocations();


            // Get Data and config paint layer storages
            const featureStorages = await this.getDataStorages();

            // if (colorReload) {
            //     this.setMapPaint();
            // }

            // Set config sources and layers
            this.$refs.mapLocations.setMapConfig();

            // Update data source map
            this.$refs.mapLocations.updateSource('s_locations_clusters',featureLocations.allCenterPointsFeature);
            this.$refs.mapLocations.updateSource('s_locations',featureLocations.allFeatures);
            this.$refs.mapLocations.updateSource('s_storages_clusters',featureStorages.allCenterPointsFeature);
            this.$refs.mapLocations.updateSource('s_storages',featureStorages.allFeatures);

            // Add events to map
            this.addEventsMap();

            let locationClone = null
            if (this.editedItem.locationFormat === "GEOBUF") {
                locationClone = await MapService.singleLocationFormat(this.editedItem)
                this.$set(this, "editedItem", locationClone)
            }

            if (
                this.IsJsonString(this.editedItem.location) &&
                this.editedItem.location &&
                this.editedItem.location !== "null"
            ) {
                if (this.editedItem.area < 0) {
                    this.$refs.mapLocations.updateAreaDrawn();
                    this.editedItem.area = this.$refs.mapLocations.getAreaDrawn();
                }
                if(!view){
                    this.$refs.mapLocations.addGeoJsonToDraw(
                      JSON.parse(this.editedItem.location)
                    );
                }
                this.zoomTo(JSON.parse(this.editedItem.location));
            } else {
                this.zoomTo(featureLocations.allFeatures, 100);
            }
        },
        setDataFormLocations(area) {
            this.editedItem.location = this.$refs.mapLocations.getLocationsDrawn();
            this.editedItem.area = area > 0 ? area : this.editedItem.area;
            this.changeArea();
        },
        changeArea() {
            this.editedItem.area = this.unitConvert(
                this.editedItem.area,
                "m²",
                "ha",
                this.units
            )
        },
        unitConvert(unitQuantity, unitFrom, unitTo, singleGroupUnits) {
            return N_UnitsService.unitConvertSameGroup(
                unitQuantity,
                unitFrom,
                unitTo,
                singleGroupUnits
            )
        },
        async getDataLocations() {
            let allFeatures = {
                type: "FeatureCollection",
                features: [],
            };
            let allCenterPointsFeature = {
                type: "FeatureCollection",
                features: [],
            }
            let location = null;
            const mergeFormat = await MapService.mergeLocationsFormats(
              this.locations
            )
            for (const locationItem of mergeFormat) {
                if (
                    this.IsJsonString(locationItem.location) &&
                    locationItem.location &&
                    locationItem.location !== "null"
                ) {
                    location = JSON.parse(locationItem.location).features[0];
                    if (location) {
                        if (!location.properties) {
                            location.properties = {}
                        }
                        location.properties.id = locationItem.locationId;
                        location.properties.gln = locationItem.gln;
                        location.properties.locationName = locationItem.name;
                        location.properties.telephone = locationItem.telephone;
                        location.properties.email = locationItem.email;
                        location.properties.area = locationItem.area;
                        location.properties.order = 2

                        if(locationItem.name === 'YANE1'){
                            console.log(locationItem.longitude)
                            console.log(isValidLatLong(locationItem.longitude, false))
                        }

                        if (
                          isValidLatLong(locationItem.latitude, true) &&
                          isValidLatLong(locationItem.longitude, false)
                        ) {

                            location.properties.lat = convertLatitude(locationItem.latitude)
                            location.properties.long = convertLongitude(locationItem.longitude)

                        }
                        location.properties.address = this.setAddressPopUpData(locationItem)
                        location.properties.locationsNames = locationItem.name

                        allFeatures.features.push(location)
                        let point = turf.center(location)
                        point.properties = location.properties
                        allCenterPointsFeature.features.push(point)
                    }
                }
            }
            return {
                'allFeatures': allFeatures,
                "allCenterPointsFeature": allCenterPointsFeature
            };
        },
        setPaint(paints) {
            if (paints && paints['fill-color']) {
                _.forEach(paints['fill-color'], (p, key) => {
                    if (p === this.editedItem.id) {
                        paints['fill-color'][key + 1] = hexToRgba(this.itemColor);
                    }
                });
            }
            return paints;
        },
        async getDataStorages() {
            let allFeatures = {
                type: "FeatureCollection",
                features: [],
            };
            let allCenterPointsFeature = {
                type: "FeatureCollection",
                features: [],
            }
            let location = null;
            const mergeFormat = await MapService.mergeLocationsFormats(
              this.storages
            )
            for (const locationItem of mergeFormat) {
                if (
                    this.IsJsonString(locationItem.location) &&
                    locationItem.location &&
                    locationItem.location !== "null"
                ) {
                    location = JSON.parse(locationItem.location).features[0];
                    if (location) {
                        if (!location.properties) {
                            location.properties = {}
                        }
                        location.properties.id = locationItem.storageDeviceId;
                        location.properties.gln = locationItem.gln;
                        location.properties.locationName = locationItem.control_label;
                        location.properties.telephone = locationItem.telephone;
                        location.properties.email = locationItem.email;
                        location.properties.area = locationItem.area;
                        location.properties.order = 1
                        if (
                          isValidLatLong(locationItem.latitude, true) &&
                          isValidLatLong(locationItem.longitude, false)
                        ) {
                            location.properties.lat = convertLatitude(locationItem.latitude)
                            location.properties.long = convertLongitude(locationItem.longitude)
                        }
                        location.properties.address =
                          this.setAddressPopUpData(locationItem)
                        location.properties.locationsNames =
                          this.setStoragePopUpData(locationItem)

                        allFeatures.features.push(location)
                        let point = turf.center(location)
                        point.properties = location.properties
                        allCenterPointsFeature.features.push(point)
                    }
                }
            }
            return {
                'allFeatures': allFeatures,
                'allCenterPointsFeature': allCenterPointsFeature
            };
        },
        IsJsonString(json) {
            try {
                JSON.parse(json);
            } catch (e) {
                return false;
            }
            return true;
        },
        zoomTo(features) {
            this.$refs.mapLocations.setMapZoom(16);
            this.$refs.mapLocations.zoomToFitBounds(features, 100);
            this.$refs.mapLocations.setMapZoom(20);
        },
        addEventsMap() {
            this.$refs.mapLocations.onMouseClickClusterZoomLayer(
              "l_locations_clusters","s_locations_clusters"
            );
            this.$refs.mapLocations.onMouseClickClusterZoomLayer(
              "l_storages_clusters","s_storages_clusters"
            )


            this.$refs.mapLocations.onMouseHoverPopUp(
              [
                  "l_storages_clusters_points_icon",
                  "l_storages_clusters_points",
                  "l_storages_center_points_icon",
                  "l_storages_center_points",
                  "l_storages_all",
                  "l_locations_clusters_points_icon",
                  "l_locations_clusters_points",
                  "l_locations_center_points_icon",
                  "l_locations_center_points",
                  "l_locations_all"
              ],
              "pointer","layers"
            )
            this.$refs.mapLocations.onMouseLeavePopUp(
              [
                  "l_storages_clusters_points_icon",
                  "l_storages_clusters_points",
                  "l_storages_center_points_icon",
                  "l_storages_center_points",
                  "l_storages_all",
                  "l_locations_clusters_points_icon",
                  "l_locations_clusters_points",
                  "l_locations_center_points_icon",
                  "l_locations_center_points",
                  "l_locations_all"
              ]
            )

        },

        setAddressPopUpData(locationItem) {
            return (
              this.checkIfExist(locationItem.address1, true, ", ") +
              this.checkIfExist(locationItem.city, true, ", ") +
              this.checkIfExist(locationItem.state, true, ", ") +
              this.checkIfExist(locationItem.country, false)
            )
        },
        setStoragePopUpData(locationItem) {
            return (
              this.checkIfExist(locationItem.primaryLocation.name, true, " | ") +
              this.checkIfExist(locationItem.control_label, false)
            )
        },
        checkIfExist(target, continueTrue, separator) {
            target = target ? target : ""
            continueTrue = continueTrue && target !== "" ? separator : ""
            return target + continueTrue
        },
    }
}