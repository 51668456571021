import { render, staticRenderFns } from "./ExtraColumnsComponent.vue?vue&type=template&id=29d52afc&scoped=true&"
import script from "./ExtraColumnsComponent.vue?vue&type=script&lang=js&"
export * from "./ExtraColumnsComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29d52afc",
  null
  
)

export default component.exports